// {
//   const viewportEl = document.querySelector('meta[name="viewport"]')
//   const mediaQueryList = window.matchMedia('(min-device-width: 375px)')

//   const onChangeQuery = (): void => {
//     const viewportContent = mediaQueryList.matches
//       ? 'width=device-width, initial-scale=1'
//       : 'width=375'

//     viewportEl?.setAttribute('content', viewportContent)
//   }

//   mediaQueryList.addListener(onChangeQuery)
//   onChangeQuery()
// }
